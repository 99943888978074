import React from "react";
import { ProductDetails } from "../../components"

const ProductPage = () => {
  return (
  <>
    <ProductDetails />

  </>
  )
}

export default ProductPage